<script>
export default {
  name: 'TitleDescriptionCardAnchor',
  props: {
    icon: {
      type: String
    },
    title: {
      type: String
    },
    description: {
      type: String
    },
    buttonTo: {
      type: String
    },
    buttonText: {
      type: String
    }
  }
}
</script>
<template>
  <div class="title-description-card-anchor--container">
    <div  class="title-description-card-anchor--title">
      <v-icon :color="getPrimaryColor">{{ icon }}</v-icon>
      <h3 class="title-description-card-anchor--title-element" :style="{'color': getPrimaryColor}">{{ $te(title) ? $t(title) : title }}</h3>
    </div>
    <p class="title-description-card-anchor--description">{{ $te(description) ? $t(description) : description }}</p>
    <v-btn class="bold btn transform-unset" :to="buttonTo" :color="getPrimaryColor" dark><v-icon size="18" color="#FFFFFF" class="mr-2">mdi-open-in-new</v-icon>{{ $te(buttonText) ? $t(buttonText) : buttonText }}</v-btn>
  </div>
</template>
<style lang="scss">
.title-description-card-anchor--container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  margin-top: 80px;
  .title-description-card-anchor--title {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    .v-icon {
      margin-right: 8px;
    }
    h3.title-description-card-anchor--title-element {
      font-family: $lato;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
    }
  }
  p.title-description-card-anchor--description {
    font-family: 'Lato';
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #242321;
    margin-bottom: 16px;
  }
}
</style>
