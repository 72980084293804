<script>
export default {
  name: 'TitleDescriptionSlot',
  props: {
    title: {
      type: String
    },
    description: {
      type: String
    }
  }
}
</script>
<template>
  <div class="title-description-slot--container">
    <h4 class="title-description-slot--title">{{ $te(title) ? $t(title) : title }}</h4>
    <p class="title-description-slot--description" v-if="description">{{ $te(description) ? $t(description) : description }}</p>
    <div class="title-description-slot--wrapper">
      <slot></slot>
    </div>
  </div>
</template>
<style lang="scss">
.title-description-slot--container {
  text-align: left;
  margin-top: 80px;
  h4.title-description-slot--title {
    font-family: $lato;
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    color: #242321;
    margin-bottom: 8px;
  }
  p.title-description-slot--description {
    font-family: 'Lato';
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #7E7D77;
  }
}
</style>
