<script>
import HomepageIndividual from '@/components/home/index/homepage-individual'
export default {
  name: 'HomepageUser',
  components: { HomepageIndividual }
}
</script>
<template>
  <div class="homepage-user--container">
    <homepage-individual/>
    <router-view/>
  </div>
</template>
<style lang="scss">
.homepage-user--container {
  .banner-text--wrapper {
    width: 100%;
    text-align: left;
    padding: 22px 24px;
    > * {
      color: $neutral-white;
      margin: 0;
    }
  }
}
</style>
