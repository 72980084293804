<script>
import UserAvatarInitials from '@/components/general/UserAvatarInitials'
export default {
  name: 'MessageItem',
  components: { UserAvatarInitials },
  props: {
    left: {
      type: Boolean,
      default: false
    },
    message: {
      type: String
    },
    status: {
      type: String
    },
    avatar: {
      type: String
    },
    sender: {
      type: String
    },
    date: {
      type: String
    },
    attachments: {
      type: Array
    }
  },
  computed: {
    processedMessage () {
      const messageWithLinks = this.message.replace(
        /(https?:\/\/[^\s]+)/g,
        '<a href="$1" target="_blank">$1</a>'
      )
      return messageWithLinks
    }
  },
  methods: {
    mountAttachmentName (rawName) {
      const fullFileName = rawName.split('/').slice(-1)[0]
      let resolvedName = ''

      if (fullFileName.length > 15) {
        resolvedName += fullFileName.slice(0, 8) + '[...]'
        resolvedName += '.' + fullFileName.split('.').slice(-1)[0]
      }

      return resolvedName
    }
  }
}
</script>
<template>
  <div class="message-item--container" :class="{'right': !left}">
    <div class="message-item--upper">
      <div class="message-item--box">
        <p class="message-item--message" v-html="processedMessage"></p>
        <div class="message-item--message-lower">
          <template v-if="attachments.length">
            <v-btn outlined v-for="attachment in attachments" :href="attachment" download class="message-item--attachment transform-unset" :key="attachment">
              <v-icon size="16px">mdi-paperclip</v-icon>
              <p class="message-item--attachment-name">{{ mountAttachmentName(attachment) }}</p>
            </v-btn>
          </template>
          <v-icon size="14px" v-if="status === 'pending'">mdi-check</v-icon>
          <v-icon size="14px" color="#23b7e5" v-else>mdi-check-all</v-icon>
        </div>
      </div>
      <v-avatar size="36px">
        <v-img v-if="!!avatar && !(avatar.includes('/empty'))" :src="avatar" height="36" width="36"></v-img>
        <user-avatar-initials v-else :user="workspace" :size="36"/>
      </v-avatar>
    </div>
    <div class="message-item--lower">
      <div class="message-item--basic-info">
        <p>{{ sender }}</p>
        <span>{{ date }}</span>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.message-item--container {
  margin: 40px 16px;
  &.right {
    .message-item--upper {
      flex-direction: row-reverse;
      .v-avatar {
        margin-left: 0;
        margin-right: 8px;
      }
    }
    .message-item--lower {
      flex-direction: row-reverse;
      padding-right: 0;
      padding-left: 48px;
    }
  }
  .message-item--upper {
    display: flex;
      .v-avatar {
        margin-left: 8px;
        margin-right: 6px;
      }
    .message-item--box {
      flex: 1;
      border: 1px solid #ccc;
      border-radius: 4px;
      text-align: left;
      padding: 16px;
      overflow-wrap: anywhere;
      .message-item--message {
        margin: 0;
        white-space: pre-wrap;
      }
      .message-item--message-lower {
        display: flex;
        justify-content: space-between;
        margin-top: 16px;
        > .v-icon {
          margin-left: auto;
        }
        .message-item--attachment {
          display: flex;
          align-items: center;
          padding: 8px;
          background: #FFFFFF;
          border-color: rgba(0, 0, 0, 0.06);
          &:hover {
            background: #f5f5f5;
          }
          p.message-item--attachment-name {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
            color: #212121;
            margin: 0;
          }
          span {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #a0a0a0;
            margin-left: 4px;
          }
        }
      }
    }
  }
  .message-item--lower {
    display: flex;
    justify-content: space-between;
    padding-right: 48px;
    .message-item--basic-info {
      display: flex;
      margin-top: 4px;
      p {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 900;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
      }
      span {
        margin-left: 8px;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #7e7d77;
      }
    }
  }
}
</style>
