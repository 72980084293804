<script>
import randomColor from 'randomcolor'
export default {
  name: 'UserAvatarInitials',
  props: {
    user: {
      type: Object,
      required: true
    },
    size: {
      type: Number,
      default: 128
    },
    color: {
      type: String,
      default: null
    },
    initialsOnly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    fontSize () {
      return this.size / 40.0 * 17 + 'px'
    },
    fontColor () {
      if (this.initialsOnly) {
        return this.color || this.userToColour(this.user)
      }
      return 'white'
    },
    backgroundColor () {
      if (this.initialsOnly) {
        return 'transparent'
      }
      return this.color || this.userToColour(this.user)
    }
  },
  methods: {
    stringToColour (str) {
      return randomColor({
        seed: str,
        luminosity: 'dark'
      })
    },
    userToColour (user) {
      return this.stringToColour((user.id || '0') +
                                 user.name +
                                 user.lastName +
                                 user.email)
    }
  }
}
</script>
<template>
  <v-avatar v-if="!!user"
          :size="128"
          :style="{'background-color': backgroundColor}">
    <strong class="initials"
            :style="{'color': fontColor,
                    'font-size': fontSize}">
      {{ ((user.name && user.lastName) ? user.name[0] + user.lastName[0] : '') || '?' }}
    </strong>
  </v-avatar>
</template>
<style scoped>
.initials {
  text-transform: uppercase;
}
</style>
