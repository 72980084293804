<script>
import TextArea from './TextArea'

export default {
  components: { TextArea },
  name: 'ModalOffer',
  props: {
    offer: {
      type: Object
    }
  },
  data () {
    return {
      showModalOfferDecline: false,
      note: ''
    }
  },
  methods: {
    close () {
      if (window.location.href.indexOf('applications') > 0) {
        this.$emit('close')
      } else {
        this.$store.dispatch('attemptGetOfferLetter').then(() => {
          this.$emit('close')
        })
      }
    },
    attemptAcceptOffer (hash) {
      this.$store.dispatch('attemptAcceptOfferLetter', hash)
        .then(() => {
          this.$store.dispatch('attemptSetFeedbackMsg', {
            type: 'success',
            title: this.$t('offer.form:accepted.success')
          })
          this.close()
        })
        .catch(() => {
          this.$store.dispatch('attemptSetFeedbackMsg', {
            type: 'error',
            title: this.$t('offer.form:accepted.error')
          })
        })
    },
    attemptDeclineOffer () {
      this.$store.dispatch('attemptDeclineOfferLetter', { hash: this.offer.hash, notes: this.note })
        .then(() => {
          this.$store.dispatch('attemptSetFeedbackMsg', {
            type: 'success',
            title: this.$t('offer.form:declined.success')
          })
          this.$store.dispatch('attemptGetOfferLetter').then(() => {
            this.$emit('showModalOffer', false)
            this.$emit('close')
          })
          this.close()
        })
        .catch(() => {
          this.$store.dispatch('attemptSetFeedbackMsg', {
            type: 'error',
            title: this.$t('offer.form:accepted.error')
          })
        })
    }
  }
}
</script>
<template>
  <v-dialog :value="true" persistent width="800px" max-width="100%">
    <v-card class="modal-offer--container">
      <div class="modal-offer--header">
        <h3>{{ showModalOfferDecline ? $t('offer.decline.title') : $t('offer.title') }}</h3>
        <v-icon class="clickable" color="#454545" @click="close">mdi-close</v-icon>
      </div>
    <template v-if="!showModalOfferDecline">
      <div class="modal-offer--sender-receiver" >
        <p>{{ $t('global:company') }}: <v-avatar size="24px"><v-img :src="offer.company.logo" height="24" width="24"></v-img></v-avatar><span>{{ offer.company.name }}</span></p>
        <p>{{ $t('global:position') }} <span>{{ offer.position.name }}</span></p>
      </div>
      <div class="modal-offer--body" ref="offer">
        {{ offer.offerletter.message }}
      </div>
      <div class="modal-offer--actions-apply">
        <v-btn class="btn mr-4" outlined :color="getPrimaryColor" :href="offer.url" >{{ $t('offer.download') }}</v-btn>
        <v-btn class="btn mr-4" @click="showModalOfferDecline = true" outlined :color="getPrimaryColor">{{ $t('offer.reject') }}</v-btn>
        <v-btn class="btn" @click="attemptAcceptOffer(offer.hash)" dark :color="getPrimaryColor"><v-icon class="mr-2" color="#fff" size="20">mdi-check</v-icon>{{ $t('offer.accept') }}</v-btn>
      </div>
    </template>
    <template v-else>
      <div class="modal-offer-decline--input-area">
        <label class="modal-offer-decline--input-label">{{ $t('offer.message.confirm.decline', { position: offer.position.name }) }}</label>
        <text-area class="modal-offer-decline--text-area-label" outlined :placeholder="$t('modal.messages:write.here')" v-model="note"></text-area>
      </div>
      <div class="modal-offer--actions-apply">
        <v-btn class="btn mr-4" @click="showModalOfferDecline = false" dark :color="getPrimaryColor">{{ $t('global:cancel') }}</v-btn>
        <v-btn :disabled="!note.length" :dark="!!note.length" outlined class="btn" @click="attemptDeclineOffer" :color="getPrimaryColor"><v-icon class="mr-2" color="getPrimaryColor" size="20">mdi-check</v-icon>{{ $t('offer.reject') }}</v-btn>
      </div>
    </template>
    </v-card>
  </v-dialog>
</template>
<style lang="scss">
.modal-offer--container {
  position: relative;
  .modal-offer--header {
    display: flex;
    justify-content: space-between;
    padding: 18px 24px;
    background-color: #FAFAFA;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    h3 {
      font-size: 16px;
      line-height: 20px;
      color: #000000;
    }
  }
  .modal-offer--sender-receiver {
    background: #FAFAFA;
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    padding: 16px;
    text-align: left;
    margin: 32px 16px 32px 16px;
    p {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #7E7D77;
      margin: 0;
      &:nth-child(1) {
        margin-bottom: 8px;
      }
      .v-avatar {
        margin: 0 8px;
      }
      span {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #000000;
      }
    }
  }
  .modal-offer--body {
    max-height: calc(100vh - 300px);
    overflow: auto;
    padding: 0 20px 40px;
    text-align: left;
    white-space: pre-line;
  }
  .modal-offer--actions-apply {
    display: flex;
    justify-content: right;
    align-items: center;
    padding: 0 24px;
    width: 100%;
    height: 68px;
    background: #FAFAFA;
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    .btn.v-btn.v-size--default {
      text-transform: none;
      height: 36px;
      font-size: 14px;
    }
  }
  .modal-offer--actions-view {
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 0 24px;
    width: 100%;
    height: 68px;
    background: #FAFAFA;
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    .btn.v-btn.v-size--default {
      text-transform: none;
      height: 36px;
      font-size: 14px;
    }
  }
  .modal-offer-decline--input-area {
    padding: 0 16px;
    margin-top: 16px;
    text-align: left;
    .modal-offer-decline--input-label {
      margin-bottom: 12px;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      color: #000000;
    }
    .modal-offer-decline--text-area-label{
      margin-top: 12px;
    }
  }
}
</style>
