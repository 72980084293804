<script>
import ModalMessages from './ModalMessages'
export default {
  name: 'PendingMessage',
  components: {
    ModalMessages
  },
  props: {
    position: {
      type: Object
    },
    company: {
      type: Object
    },
    unreadAmount: {
      type: String
    }
  },
  data () {
    return {
      showModalMessage: false,
      allMessages: []
    }
  },
  created () {
    this.$store.dispatch('attemptGetThreadMessages', this.position.id).then(data => {
      this.allMessages = data.sort((a, b) => new Date(a.date) - new Date(b.date))
    })
  },
  methods: {
    markMessageRead () {
      const msgs = this.allMessages.filter(m => (m.status !== 'opened' && m.isCompany)).map(m => m._id)
      const body = {
        msgs: msgs,
        time: Date.now(),
        status: 'opened',
        channel: 'enlizt-app'
      }
      this.$store.dispatch('attemptPutReadMessage', body)
    }
  }
}
</script>
<template>
  <div class="pending-message--container">
    <div class="pending-message--info">
      <h3>{{ position.name }}</h3>
      <div class="pending-message--owner-info">
        <v-avatar size="22">
          <v-img :src="company.image" height="22" width="22"></v-img>
        </v-avatar>
        <p>{{ company.name }}</p>
      </div>
    </div>
    <div class="pending-message-action">
      <v-btn class="transform-unset" :color="getPrimaryColor" dark @click="(showModalMessage = true, markMessageRead())"><v-icon class="mr-2" size="14" color="#fff">mdi-message</v-icon>{{ $t('global:messages') }}</v-btn>
      <span class="pending-message--amount">{{ unreadAmount }}</span>
    </div>
    <modal-messages
      v-if="showModalMessage"
      :company="company"
      :position="position"
      @close="showModalMessage = false"
      @refreshUnreadMessagesModal="$emit('refreshUnreadMessages')"
    ></modal-messages>
  </div>
</template>
<style lang="scss">
.pending-message--container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  margin-bottom: 8px;
  .pending-message--info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-right: 24px;
    h3 {
      font-family: 'Lato';
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      color: #000000;
    }
    .pending-message--owner-info {
      display: flex;
      align-items: center;
      margin-top: 8px;
      .v-avatar {
        margin-right: 8px;
        border-radius: 100px;
      }
      p {
        font-family: 'Lato';
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
        margin-bottom: 0;
      }
    }
  }
  .pending-message-action {
    position: relative;
    .pending-message--amount {
      position: absolute;
      right: 0;
      top: 0;
      height: 20px;
      width: 20px;
      transform: translate(25%, -25%);
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(0deg, rgba(214, 95, 87, 0.2), rgba(214, 95, 87, 0.2)), #FFFFFF;
      border: 1px solid #D65F57;
      box-shadow: 0px 1px 3px rgba(255, 49, 35, 0.4);
      border-radius: 100px;
      color: #D65F57;
      font-size: 10px;
      line-height: 12px;
      font-weight: bold;
    }
  }
}
</style>
