<script>
import ConfirmationModal from '@/components/general/ConfirmationModal'
export default {
  components: { ConfirmationModal },
  name: 'PositionInvite',
  props: {
    position: {
      type: Object
    },
    company: {
      type: Object
    },
    message: {
      type: Object
    },
    inviteId: {
      type: String
    }
  },
  data () {
    return {
      showConfirmationModal: false
    }
  },
  methods: {
    attemptResponseInvite (feedback) {
      const data = {
        inviteId: this.inviteId,
        feedback: feedback
      }
      this.$store.dispatch('attemptPutResponseInvite', data).then(() => {
        this.$emit('refreshUserApplicationInvites')
      })
      if (feedback === 'declined') this.$emit('confirmReject')
    }
  }
}
</script>
<template>
  <div class="position-invite--container">
    <div class="position-invite--info">
      <div class="position-invite--owner-info">
        <v-avatar size="22">
          <v-img :src="company.logo" height="22" width="22"></v-img>
        </v-avatar>
        <p>{{ company.name }}</p>
      </div>
      <h3>{{ position.title }}</h3>
    </div>
    <div class="position-invite--bottom">
      <div class="position-invite--tag">
        <span class="position-invite--status">
          <v-icon class="ml-1 mr-1" size="6">mdi-circle</v-icon>
          {{ $t('global:pending') }}!
        </span>
      </div>
      <div class="position-invite--buttons">
        <v-btn class="bold transform-unset mr-4" :color="getPrimaryColor" outlined @click="showConfirmationModal = true">{{ $t('global:reject') }}</v-btn>
        <v-btn class="bold transform-unset" :color="getPrimaryColor" dark @click="attemptResponseInvite('accepted')"><v-icon class="mr-2" size="14" color="#fff">mdi-check</v-icon>{{ $t('global:accept') }}</v-btn>
      </div>
    </div>
    <confirmation-modal
      :show="showConfirmationModal"
      :title="$t('position.invite.reject.modal:title')"
      :description="$t('position.invite.reject.modal:info')"
      :confirmText="$t('global:reject')"
      :btnConfirmText="false"
      @confirm="attemptResponseInvite('declined')"
      :cancelText="$t('global:cancel')"
      @cancel="showConfirmationModal = false"
    />
  </div>
</template>
<style lang="scss">
.position-invite--container {
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  margin-bottom: 8px;
  .position-invite--info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 16px 8px;
    h3 {
      font-family: 'Lato';
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      color: #000000;
    }
    .position-invite--owner-info {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      .v-avatar {
        margin-right: 8px;
        border-radius: 100px;
      }
      p {
        font-family: 'Lato';
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
        margin-bottom: 0;
      }
    }
  }
  .position-invite--bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;
    padding: 16px;
    background: #FAFAFA;
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    .position-invite--tag {
      .position-invite--status {
        padding: 6px 12px 6px 8px;
        max-height: 22px;
        border-radius: 100px;
        font-family: $lato;
        font-weight: 700;
        font-size: 12px;
        line-height: 12px;
        background-color: rgba(0, 0, 0, .1);
        color: #D65F57;
        background: linear-gradient(0deg, rgba(214, 95, 87, 0.2), rgba(214, 95, 87, 0.2)), #FFFFFF;
        border: 1px solid #D65F57;
        text-transform: uppercase;
        .v-icon {
          margin-right: 4px;
          color: #D65F57;
        }
      }
    }
    .position-invite--buttons {
      .v-btn {
        font-size: 12px;
      }
    }
  }
}
</style>
