<script>
export default {
  name: 'FileUpload',
  data () {
    return {
      file: null
    }
  },
  methods: {
    mountAttachmentName (rawName) {
      const fullFileName = rawName && rawName.split('/').slice(-1)[0]
      let resolvedName = ''

      if (fullFileName.length > 15) {
        resolvedName += fullFileName.slice(0, 8) + '[...]'
        resolvedName += '.' + fullFileName.split('.').slice(-1)[0]
      } else {
        resolvedName = rawName
      }

      return resolvedName
    },
    removeFile () {
      this.file = null
      this.$emit('input', null)
    }
  }
}
</script>
<template>
  <div class="file-upload--container">
    <v-file-input class="file-upload--attachment-btn" hide-input v-model="file" v-if="!file" @change="$emit('input', file)">
      <v-icon size="16px">mdi-paperclip</v-icon>
    </v-file-input>
    <v-btn outlined class="file-upload--attachment transform-unset" v-if="file">
      <v-icon size="16px">mdi-paperclip</v-icon>
      <p class="file-upload--attachment-name">{{ mountAttachmentName(file.name) }}</p>
      <div class="remove-file--icon cursor-pointer" @click="removeFile">
        <v-icon size="16px">mdi-close</v-icon>
      </div>
    </v-btn>
  </div>
</template>
<style lang="scss">
.file-upload--container {
  .file-upload--attachment-btn.v-input {
    height: 32px;
    width: 32px;
    min-width: 32px;
    padding: 0;
  }
  .file-upload--attachment.v-btn {
    padding: 0 10px;
    .file-upload--attachment-name {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      color: #212121;
      margin: 0 0 0 4px;
    }
    .remove-file--icon {
      margin-left: 8px;
      height: 20px;
      width: 20px;
      background: #E1E1E1;
      border-radius: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
