<script>
import ModalOffer from './ModalOffer'
export default {
  name: 'Offer',
  components: {
    ModalOffer
  },
  props: {
    offer: {
      type: Object
    }
  },
  data () {
    return {
      showModalOffer: false
    }
  }
}
</script>
<template>
  <div class="offer--container">
    <div class="offer--info">
      <div class="offer--owner-info">
        <v-avatar size="22">
          <v-img :src="offer.company.logo" height="22" width="22"></v-img>
        </v-avatar>
        <p>{{ offer.company.name }}</p>
      </div>
      <h3>{{ offer.position.name }}</h3>
    </div>
    <div class="offer-action">
      <v-btn class="transform-unset" :color="getPrimaryColor" dark @click="(showModalOffer = true)"><v-icon class="mr-2" size="14" color="#fff"></v-icon>{{ $t('offer.view') }}</v-btn>
    </div>
    <modal-offer
      v-if="showModalOffer"
      :offer="offer"
      @close="showModalOffer = false"
    ></modal-offer>
  </div>
</template>
<style lang="scss">
.offer--container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  margin-bottom: 8px;
  .offer--info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-right: 24px;
    h3 {
      font-family: 'Lato';
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      color: #000000;
    }
    .offer--owner-info {
      display: flex;
      align-items: center;
      margin-top: 8px;
      .v-avatar {
        margin-right: 8px;
        border-radius: 100px;
      }
      p {
        font-family: 'Lato';
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
        margin-bottom: 0;
      }
    }
  }
  .offer-action {
    position: relative;
    .offer--amount {
      position: absolute;
      right: 0;
      top: 0;
      height: 20px;
      width: 20px;
      transform: translate(25%, -25%);
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(0deg, rgba(214, 95, 87, 0.2), rgba(214, 95, 87, 0.2)), #FFFFFF;
      border: 1px solid #D65F57;
      box-shadow: 0px 1px 3px rgba(255, 49, 35, 0.4);
      border-radius: 100px;
      color: #D65F57;
      font-size: 10px;
      line-height: 12px;
      font-weight: bold;
    }
  }
}
</style>
